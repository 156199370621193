import React from 'react';
import { Container } from 'theme-ui';

export function HomeTemplate({ page, renderSections, ...props }) {
  return (
    <Container variant="layout.full" data-comp="HomeTemplate">
      {renderSections()}
    </Container>
  );
}
